.listItem {
  width: 120px;
  height: 120px;
  background-color: var(--main-color);
  margin-right: 20px;
  overflow: hidden;
  cursor: pointer;
  color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 0px rgb(119, 116, 116);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .prueba {
    width: 100%;
    height: 140px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    width: 320px;
    height: 300px;
    //position: absolute;
    transform: scale(1.1); 
    margin-top: -180px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.07);
    box-shadow: 1px 1px 15px 1px rgb(233, 231, 231);
    border-radius: 10px;

    img {
      height: 140px;
    }

    .itemInfo {
      display: flex;
      flex-direction: column;
      padding: 5px;

      .icons {
        display: flex;
        margin-bottom: 10px;

        .icon {
          border: 2px solid white;
          padding: 5px;
          border-radius: 50%;
          margin-right: 10px;
          font-size: 16px;
        }
      }
      .itemInfoTop {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
        color: gray;

        .limit {
          border: 1px solid gray;
          padding: 1px 3px;
          margin: 0 10px;
        }
      }

      .desc {
        font-size: 13px;
        margin-bottom: 10px;
      }

      .genre {
        font-size: 14px;
        color: lightgray;
      }
    }
  }
}
